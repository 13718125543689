import React, { useState, useEffect } from "react";
import Toast from "toasted-notes";
import TopHeader from "./TopHeader";
import IncomeStep from "./IncomeStep";
import ExpenseStep from "./ExpenseStep";
import SummaryStep from "./SummaryStep";
import BottomNavigation from "../navigation/BottomNavigation";
import BottomPagination from "../navigation/BottomPagination";
import { injectIntl } from "react-intl";
import uuidv4 from "uuid/v4";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export const BudgetContext = React.createContext();
const INCOME_KEY = "savermiddleeast.incomes";
const EXPENSE_KEY = "savermiddleeast.expenses";

function Index({ intl }) {
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [currentStep, setCurrentstep] = useState(1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const incomeJSON = localStorage.getItem(INCOME_KEY);
    if (incomeJSON != null) {
      setIncomes(JSON.parse(incomeJSON));
    } else {
      setIncomes([initialIncome]);
    }

    const expenseJSON = localStorage.getItem(EXPENSE_KEY);
    if (expenseJSON != null) setExpenses(JSON.parse(expenseJSON));

    setLoading(true);
  }, []);

  useEffect(() => {
    incomes.length && localStorage.setItem(INCOME_KEY, JSON.stringify(incomes));
  }, [incomes]);

  useEffect(() => {
    expenses.length &&
      localStorage.setItem(EXPENSE_KEY, JSON.stringify(expenses));
  }, [expenses]);

  const primaryIncome = incomes.find((i) => i.primary === 1);

  const budgetContextValue = {
    handleIncomeAdd,
    handleIncomeChange,
    handleIncomeDelete,
    handleExpenseAdd,
    handleExpenseChange,
    handleExpenseDelete,
    primaryIncome,
    incomes,
    expenses,
  };

  function handleIncomeAdd() {
    const newIncome = {
      id: uuidv4(),
      name: "",
      amount: 0,
      pay_period: 1,
      primary: 0,
    };

    setIncomes([...incomes, newIncome]);
  }

  function handleIncomeChange(id, income) {
    const newIncomes = [...incomes];
    const index = newIncomes.findIndex((i) => i.id === id);
    newIncomes[index] = income;
    setIncomes(newIncomes);
  }

  function handleIncomeDelete(id) {
    setIncomes(incomes.filter((income) => income.id !== id));
  }

  function handleExpenseAdd() {
    const newExpense = {
      id: uuidv4(),
      name: "",
      amount: 0,
      pay_period: 1,
    };

    setExpenses([...expenses, newExpense]);
  }

  function handleExpenseChange(id, expense) {
    const newExpenses = [...expenses];
    const index = newExpenses.findIndex((i) => i.id === id);
    newExpenses[index] = expense;
    setExpenses(newExpenses);
  }

  function handleExpenseDelete(id) {
    setExpenses(expenses.filter((e) => e.id !== id));
  }

  function handleCurrentStep(newStep) {
    if (primaryIncome.amount > 0) {
      setCurrentstep((prevCurrentStep) => {
        return prevCurrentStep + newStep;
      });

      return true;
    }

    Toast.notify(intl.messages["error.primary_income"], {
      position: "bottom-left",
      duration: 3000,
    });

    return true;
  }

  return (
    <div className="screen-container pagination">
      {loading && (
        <BudgetContext.Provider value={budgetContextValue}>
          <TopHeader currentStep={currentStep} totalSteps={3} />
          {currentStep === 1 && <IncomeStep />}
          {currentStep === 2 && <ExpenseStep />}
          {currentStep === 3 && <SummaryStep />}
          <BottomPagination
            currentStep={currentStep}
            totalSteps={3}
            handleCurrentStep={handleCurrentStep}
          />
          <BottomNavigation />
        </BudgetContext.Provider>
      )}
    </div>
  );
}

const initialIncome = {
  id: uuidv4(),
  name: "Primary Income",
  amount: 0,
  pay_period: 1,
  primary: 1,
};

export default injectIntl(Index);
